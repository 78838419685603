import {Box, Stack} from "@mui/material";
import EuropeanFundsFullLogo from "./EuropeanFundsFullLogo.png";
import {List} from "@beesset/ui-components";
import {useTranslation} from "react-i18next";

const EuropeanFundsContent = () => {
    const {t} = useTranslation();

    return <Stack justifyContent="center" alignItems="center" spacing={{xs: 3, md: 5}}>
        <Box>
            <img alt={t("componentsNames.europeanFunds")} src={EuropeanFundsFullLogo} width="100%" style={{maxHeight: "110px"}}/>
        </Box>
        <List
            descriptors={[{
                name: t("components.europeanFunds.title1"),
                value: t("components.europeanFunds.description1")
            }, {
                name: t("components.europeanFunds.title2"),
                value: t("components.europeanFunds.description2")
            }, {
                name: t("components.europeanFunds.title3"),
                value: t("components.europeanFunds.description3")
            }, {
                name: t("components.europeanFunds.title4"),
                value: t("components.europeanFunds.description4")
            }]}
        />
    </Stack>
}

export default EuropeanFundsContent;