import {invokePOST} from "@beesset/rest";
import {BoxWithMargin, FormContainer, hideLoader, showLoader} from "@beesset/ui-components";
import SendIcon from "@mui/icons-material/Send";
import {Grid, IconButton, Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {Box} from "@mui/system";
import React from "react";
import {useTranslation} from "react-i18next";
import JsonInspector from "react-json-inspector";
import "./View.css";
import DownloadIcon from '@mui/icons-material/Download';
import download from "downloadjs";

const JsonViewer = ({
                        httpMethod = "POST",
                        uri,
                        requestParameters,
                        convertRequest,
                        isExpanded
                    }) => {
    const {t} = useTranslation();
    const [dataRequest, setDataRequest] = React.useState(null);
    const [data, setData] = React.useState({
        response: null,
        showViewer: false,
        isSuccess: false,
    });

    const config = React.useMemo(() => {
        return {
            translations: {
                httpMethod: t("views.jsonViewer.httpMethod"),
                methodUri: t("views.jsonViewer.methodUri"),
                methodParameters: t("views.jsonViewer.methodParameters"),
                methodHasNoParameters: t("views.jsonViewer.methodHasNoParameters"),
                methodSuccessResult: t("views.jsonViewer.methodSuccessResult"),
                methodFailureResult: t("views.jsonViewer.methodFailureResult"),
                viewerDisabled: t("views.jsonViewer.viewerDisabled"),
                methodRequest: t("views.jsonViewer.methodRequest"),
                filter: t("commonComponents.filter.one"),
                search: t("commonComponents.filter.search"),
                send: t("button.send"),
            },
        };
    }, [t]);

    React.useEffect(() => {
        if (dataRequest != null) {
            showLoader();
            invokePOST({
                uri: uri,
                timeout: 60000,
                handlers: {
                    success: (response) => {
                        setData({
                            response: response,
                            showViewer: Buffer.from(JSON.stringify(response)).length < 5242880,
                            isSuccess: true,
                        });
                    },
                    failure: (response) => {
                        setData({
                            response: response || {
                                error: "Unexpected Error"
                            },
                            showViewer: true,
                            isSuccess: false,
                        });
                    },
                    both: () => {
                        hideLoader();
                    }
                },
                request: dataRequest,
                showSnackbar: false,
            });
        }
    }, [dataRequest]);


    console.log(data);
    return (
        <BoxWithMargin>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} lg={4}>
                    <Stack spacing={2}>
                        <div>
                            <Typography variant="subtitle2">{config.translations.httpMethod}</Typography>
                            <Typography variant="body2" color="text.secondary">{httpMethod}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2">{config.translations.methodUri}</Typography>
                            <Typography variant="body2" color="text.secondary">{uri}</Typography>
                        </div>
                        <Stack spacing={0.5}>
                            <Typography variant="subtitle2">{config.translations.methodParameters}</Typography>
                            {!requestParameters && <Typography variant="body2"
                                                               color="text.secondary">{config.translations.methodHasNoParameters}</Typography>}
                            <FormContainer
                                onSuccess={(request) => {
                                    setDataRequest(
                                        convertRequest ? convertRequest(request) : request
                                    );
                                }}
                            >
                                {requestParameters}
                                <Button
                                    sx={{marginTop: "15px"}}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    endIcon={<SendIcon/>}
                                >
                                    {config.translations.send}
                                </Button>
                            </FormContainer>
                        </Stack>
                        {(dataRequest !== null && Object.keys(dataRequest).length > 0) && (
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography sx={{paddingBottom: "10px"}}>
                                        {config.translations.methodRequest}
                                    </Typography>
                                    <IconButton
                                        color="info"
                                        onClick={() => {
                                            let u = uri.split("/");
                                            let fileToSave = new Blob([JSON.stringify(dataRequest, undefined, 2)], {
                                                type: 'application/json'
                                            });
                                            download(fileToSave, `${httpMethod}_request_${u[u.length - 1]}.json`);
                                        }}
                                    >
                                        <DownloadIcon/>
                                    </IconButton>
                                </Stack>
                                <Box
                                    sx={{
                                        maxHeight: {
                                            md: "300px",
                                        },
                                        overflow: {
                                            md: "auto",
                                        },
                                        paddingRight: {
                                            md: "20px",
                                        },
                                    }}
                                >
                                    <JsonInspector
                                        data={dataRequest}
                                        search={false}
                                        isExpanded={() => true}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    {data.response && (
                        <Box maxWidth={{
                            xs: "unset",
                            md: "500px"
                        }}>
                            {!data.isSuccess && (
                                <Typography color="error" pb={2}
                                            variant="subtitle2">{config.translations.methodFailureResult}</Typography>
                            )}
                            {data.isSuccess && (
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>
                                        {config.translations.methodSuccessResult}
                                    </Typography>
                                    <IconButton color="info"
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        let u = uri.split("/");
                                                        let fileToSave = new Blob([JSON.stringify(data.response, undefined, data.showViewer ? 2 : 0)], {
                                                            type: 'application/json'
                                                        });
                                                        download(fileToSave, `${httpMethod}_response_${u[u.length - 1]}.json`);
                                                    }, 500);
                                                }}
                                    >
                                        <DownloadIcon/>
                                    </IconButton>
                                </Stack>
                            )}
                            <Box
                                sx={{
                                    maxHeight: {
                                        md: "500px",
                                    },
                                    overflow: {
                                        md: "auto",
                                    },
                                    mr: "15px",
                                    paddingRight: {
                                        md: "20px",
                                    },
                                }}
                            >
                                {!data.isSuccess && (
                                    <JsonInspector
                                        data={data.response}
                                        verboseShowOriginal={true}
                                        search={false}
                                    />
                                )}
                                {data.isSuccess && <React.Fragment>
                                    {data.showViewer
                                        ? <JsonInspector
                                            data={data.response}
                                            verboseShowOriginal={true}
                                            filterOptions={{ignoreCase: true}}
                                            search={(props) => {
                                                return (
                                                    <TextField
                                                        style={{marginTop: "10px"}}
                                                        fullWidth
                                                        label={config.translations.filter}
                                                        placeholder={config.translations.search + "..."}
                                                        margin="normal"
                                                        onChange={(e) => {
                                                            props.onChange(e.target.value);
                                                        }}
                                                    />
                                                );
                                            }}
                                            isExpanded={isExpanded}
                                        />
                                        : <Typography variant="body2" color="error.light">
                                            {config.translations.viewerDisabled}
                                        </Typography>
                                    }
                                </React.Fragment>}
                            </Box>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </BoxWithMargin>
    );
};

export default JsonViewer;
