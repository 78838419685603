import {styled} from "@mui/material/styles";
import {Stack} from "@mui/material";
import logo from "./logo.png";

const Image = styled('div')({
    height: "100%",
    display: "block",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center"
});

const Logo = ({width, src, sx = {}}) => {
    return <Image sx={{
        width,
        backgroundImage: `url(${src})`,
        ...sx
    }}/>
}

const LogoComponent = ({height = "170px"}) => {
    return <Stack
        direction="row"
        justifyContent="center"
        maxWidth="350px"
        width="100%"
        height={height}
    >
        <Logo width={"100%"} src={logo}/>
    </Stack>
}

export {
    LogoComponent
};