import {TextField} from "@mui/material";
import React from "react";

const EditableTextCell = ({
                              value: initialValue = "",
                              row: {index},
                              column: {id},
                              updateValues,
                              textFieldProps = {}
                          }) => {
    const [value, setValue] = React.useState(initialValue)

    const onChange = e => {
        if (textFieldProps.type === "number") {
            if (isNaN(e.target.valueAsNumber)) {
                setValue("");
                return;
            } else if (e.target.valueAsNumber.getPrecision() > 2) {
                setValue(e.target.valueAsNumber.toFixed(2));
                return;
            }
            setValue(e.target.valueAsNumber);
        } else {
            setValue(e.target.value);
        }
    }

    const onBlur = () => {
        updateValues(index, id, value)
    }

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return (
        <TextField
            size="small"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            {...textFieldProps}
            sx={{
                minWidth: "150px"
            }}
        />
    );
};

export default EditableTextCell;