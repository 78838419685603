import {DateUtils} from "@beesset/common-utils";
import {DateField} from "@beesset/ui-components";
import {Stack} from "@mui/material";
import * as React from "react";
import {useFormContext} from "react-hook-form";

const DatePeriodField = ({
                             fromDateLabel,
                             toDateLabel,
                             fromDateName,
                             toDateName,
                             fromDateProps,
                             toDateProps,
                             required = true
                         }) => {
    const {watch, setValue, getValues} = useFormContext();
    const [bean, setBean] = React.useState(getValues());

    React.useEffect(() => {
        const subscription = watch((value) => {
            setBean(value);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [watch]);

    React.useEffect(() => {
        if (
            !bean[fromDateName] ||
            DateUtils.isBefore({
                dateA: bean[toDateName],
                dateB: bean[fromDateName],
            })
        ) {
            if (bean[toDateName]) {
                setValue(toDateName, null);
            }
        }
    }, [bean]);

    console.log("Xxx")
    return (
        <Stack
            direction={{
                xs: "column",
                sm: "row",
            }}
            spacing={1}
            alignItems="baseline"
            marginTop="10px"
        >
            <DateField
                required={required}
                name={fromDateName}
                label={fromDateLabel}
                sx={{marginTop: "unset", width: "100%"}}
                {...fromDateProps}
            />
            <DateField
                required={required}
                name={toDateName}
                label={toDateLabel}
                disabled={bean[fromDateName] ? false : true}
                minDate={
                    bean[fromDateName] ? DateUtils.getDate(bean[fromDateName]) : null
                }
                {...toDateProps}
            />
        </Stack>
    );
};

export default DatePeriodField;
